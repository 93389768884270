<script setup lang="ts">
import dayjs from 'dayjs';

const date = ref(dayjs().toDate());
</script>
<template>
  <div class="grid gap-4">
    <ui-card :title="$t('component-library.calendar_by_day')">
      <div class="flex justify-start items-start gap-4">
        <div class="flex justify-start items-start flex-col">
          <ui-form-input-date-day-calendar
            v-model="date"
            :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
          />
        </div>
        <div class="flex justify-start items-start flex-col text-sm text-gray-600 font-medium">
          Date: {{ dayjs(date).format('DD/MM/YYYY') }}
        </div>
      </div>
    </ui-card>

    <ui-card :title="$t('component-library.calendar_by_week')">
      <div class="flex justify-start items-start gap-4">
        <div class="flex justify-start items-start flex-col">
          <ui-form-input-date-week-calendar
            v-model="date"
            :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
          />
        </div>
        <div class="flex justify-start items-start flex-col text-sm text-gray-600 font-medium">
          Date: {{ dayjs(date).format('DD/MM/YYYY') }}
        </div>
      </div>
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-day')">
      <ui-form-input-date-new name="date" />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-day-with-disabled-days')">
      <ui-form-input-date-new name="date" :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]" />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-day-with-disabled-days-with-custom-color')">
      <ui-form-input-date-new
        name="date"
        :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
        disabled-date-color="bg-amber-50 text-amber-500"
      />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-day-with-disabled-days-with-tooltip')">
      <ui-form-input-date-new
        name="date"
        :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
        disabled-date-color="bg-amber-50 text-amber-500"
        disabled-tooltip="This day is disabled"
      />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-week')">
      <ui-form-input-date-new name="date" selection-type="week" />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-week-with-disabled-days')">
      <ui-form-input-date-new
        name="date"
        selection-type="week"
        :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
      />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-week-with-disabled-days-with-custom-color')">
      <ui-form-input-date-new
        name="date"
        selection-type="week"
        :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
        disabled-date-color="bg-amber-50 text-amber-500"
      />
    </ui-card>

    <ui-card :title="$t('component-library.date-selector-by-week-with-disabled-days-with-tooltip')">
      <ui-form-input-date-new
        name="date"
        selection-type="week"
        :rules="[{ type: 'disabled-day-after', value: [dayjs().add(1, 'day').toDate()] }]"
        disabled-date-color="bg-amber-50 text-amber-500"
        disabled-tooltip="This week is disabled"
      />
    </ui-card>
  </div>
</template>
